import { useEffect, useState } from "react";
import Echo from "laravel-echo";

const useSocket = () => {
    const [echo, setEcho] = useState(null);

    useEffect(() => {
        window.Pusher = require('pusher-js');

        const newEcho = new Echo({
            broadcaster: "pusher",
            key: "GoofNBCH",
            // key: "7ae046560a0ed83ad8c7",
            cluster: "mt1",
            // wsHost: "backend.cyproapp.com",
            wsHost: window.location.hostname,
            forceTLS: false, // Ensure this is true for wss
            disableStats: false,
            // disableStats: true,
            wsPort: 6001,
            encrypted: false,
            wssPort: 6001,
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }
        });

        // newEcho.connector.pusher.connection.bind('error', (error) => {
        //     console.error('WebSocket connection error:', error);
        // });

        setEcho(newEcho);

        return () => {
            newEcho.disconnect(); // Clean up on unmount
        };
    }, []);

    return echo;
};

export default useSocket;


